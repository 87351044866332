/**
 * Simply logs a warning to console only in development mode.
 * @param msg
 * @param extra
 */
export const logDevWarning = (msg: string, ...extra: string[]): void => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(msg, ...extra) // eslint-disable-line
  }
}
