import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { BadDelegateLink } from './core'
import { SchedulePage, SharedCleanPageContainer } from './schedule'
import {
  SHARED_CLEAN_INVALID_URL,
  SHARED_CLEAN_ROOT_URL,
  SHARED_TICKET_INVALID_URL,
  SHARED_TICKET_ROOT_URL,
} from './schedule/schedule.utils'
import {
  AccessHistoryPageContainer,
  AccessPageContainer,
  MyUnitsPageContainer,
  SettingsPageContainer,
  SupportPageContainer,
} from './settings'
import { TicketsPageContainer } from './tickets'
import { SharedTicketPageContainer } from './tickets/SharedTicketPage'

interface GuestworksRouterProps {
  initialUrl: string
}

export const GuestworksRouter: React.FC<GuestworksRouterProps> = React.memo(
  ({ initialUrl }) => {
    return (
      <Routes>
        <Route element={<SchedulePage />} path="/cleans" />
        <Route element={<TicketsPageContainer />} path="/tickets" />
        <Route element={<MyUnitsPageContainer />} path="/settings/homes" />
        <Route element={<SupportPageContainer />} path="/settings/support" />
        <Route
          element={<AccessHistoryPageContainer />}
          path="/settings/access/log"
        />
        <Route element={<AccessPageContainer />} path="/settings/access" />
        <Route element={<SettingsPageContainer />} path="/settings" />
        <Route
          path="/oauth/callback"
          element={<Navigate to={initialUrl || '/cleans'} />}
        />
        <Route element={<BadDelegateLink />} path={SHARED_CLEAN_INVALID_URL} />
        <Route element={<BadDelegateLink />} path={SHARED_TICKET_INVALID_URL} />
        <Route
          element={<SharedCleanPageContainer />}
          path={SHARED_CLEAN_ROOT_URL}
        />
        <Route
          element={<SharedTicketPageContainer />}
          path={SHARED_TICKET_ROOT_URL}
        />
        <Route path="*" element={<Navigate to="/cleans" />} />
      </Routes>
    )
  },
)
