export const CleanDetailDrawerId = 'CleanDetailDrawer'

/**
 * Attempts to reset the scroll position for the clean detail drawer.
 * This is useful for maintaining consistent and predictable behavior e.g.
 * when changing tabs within the drawer.
 */
export const scrollToDrawerTop = (): void => {
  const drawerRoot = document.querySelector(`#${CleanDetailDrawerId}`)
  if (drawerRoot?.scrollTo) {
    drawerRoot.scrollTo(0, 0)
  }
}
